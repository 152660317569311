import React, { useRef, useState } from "react";
import Header from "../../comp/Header";
import "./Career.css";
import NewFooter from "../../comp/NewFooter";
import Modal from "react-modal";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { Helmet } from "react-helmet";

enum JOBSHEADING {
  DEVLOPER,
  MARKETING,
  DESIGN,
}

enum MODELFILL {
  PERSONNEL,
  WORK,
  RESUME,
}

interface PERSONNELINFO {
  personelFName: string;
  personelLName: string;
  personelEmail: string;
  personellinkedIn: string;
  personelPhone: string;
  personelAddress: string;
}

interface WORK {
  workTotalExperience: string;
  workCurrentCTC: string;
  workExpectedCTC: string;
  workNoticePeriod: string;
  workRelocate: boolean;
}

const CareerPage: React.FC = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  const jobsRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef(null);
  const [jobsActive, setJobsActive] = useState<JOBSHEADING>(
    JOBSHEADING.DEVLOPER
  );
  const [modalIsOpen, setIsOpen] = React.useState<boolean>(false);
  const [modelFill, setModelFill] = useState<MODELFILL>(MODELFILL.PERSONNEL);
  const [locate, setLocate] = useState<boolean>(true);
  const [jobApplied, setJobApplied] = useState<string>("");
  const [date, setDate] = useState<String>(dd + "/" + mm + "/" + yyyy);

  const [personelData, setPersonelData] = useState<PERSONNELINFO>({
    personelFName: "",
    personelAddress: "",
    personelEmail: "",
    personellinkedIn: "",
    personelLName: "",
    personelPhone: "",
  });
  const [workData, setworkData] = useState<WORK>({
    workCurrentCTC: "",
    workExpectedCTC: "",
    workNoticePeriod: "",
    workRelocate: true,
    workTotalExperience: "",
  });
  const [resume, setResume] = useState<any>([]);
  const storage = getStorage();
  const storageRef = ref(storage, "RESUME");

  const submitPersonel = (e: any) => {
    e.preventDefault();
    if (
      personelData.personelFName.length > 2 &&
      personelData.personelLName.length > 2 &&
      personelData.personelEmail.length > 2 &&
      personelData.personelPhone.length > 2
    ) {
      setModelFill(MODELFILL.WORK);
    } else {
      alert("Please fill required fields");
    }
  };

  const submitWork = (e: any) => {
    e.preventDefault();
    if (
      workData.workCurrentCTC.length === 0 ||
      workData.workExpectedCTC.length === 0 ||
      workData.workTotalExperience.length === 0
    ) {
      alert("Please fill required fields");
    } else {
      setModelFill(MODELFILL.RESUME);
    }
  };

  function afterOpenModal() {
    console.log("Model Opened");
  }

  function submitResume(e: any) {
    e.preventDefault();
    if (!resume[0]) {
      alert("Please upload your CV ");
      return;
    }
    const storageRef = ref(storage, `/resumes/${resume[0][0].name}.pdf`);
    const uploadTask = uploadBytesResumable(storageRef, resume[0][0]);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log(percent);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          if (
            personelData.personelFName.length > 0 &&
            personelData.personelLName.length > 0 &&
            personelData.personelEmail.length > 0 &&
            personelData.personelPhone.length > 0 &&
            workData.workTotalExperience.length > 0 &&
            workData.workCurrentCTC.length > 0
          ) {
            console.log("Gone to Form");

            setDoc(doc(firestore, "ResumeCarrer", personelData.personelFName), {
              personelData,
              workData,
              cv: url,
              position: jobApplied,
              date: date,
            })
              .then(() => {
                alert("Thank you for applying");
              })
              .catch((error) => console.log(error));
          } else {
            alert("Please Fill the required Fields");
          }
          console.log(url);
        });
      }
    );
  }

  function openModel(value: string) {
    setJobApplied(value);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const changeLocationPreferance = () => {
    setLocate((prev) => !prev);
  };

  const customStyles: Modal.Styles = {
    content: {
      top: "53%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      border: "none",
      scrollbarWidth: "none",
      msOverflowStyle: "none",
    },
    overlay: {
      background: "rgba(0,0,0,0.8)",
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Join Our Team - Explore Exciting Career Opportunities | Rareprob
        </title>
        <meta
          name="description"
          content="Discover rewarding career paths with us. Explore job openings, professional growth, and a vibrant work environment as you advance your career."
        />
      </Helmet>
      <Header color="bg-black" page="career" />
      <main className="relative careerPage" id="start">
        <img
          src="/images/carrer/Path 6689.png"
          alt="Top background"
          className="carrer_top_bg"
        />
        <img
          src="/images/carrer/Path 6688.png"
          alt=""
          className="carrer_top_bg_right"
        />
        <Modal
          ref={modalRef}
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          portalClassName=""
          bodyOpenClassName=""
          id="career_model"
        >
          <div className="model_container ">
            <div className="modal_top_heading">
              <button
                className={
                  modelFill === MODELFILL.PERSONNEL ? "modal_active" : ""
                }
                onClick={() => setModelFill(MODELFILL.PERSONNEL)}
              >
                Personal Info
              </button>
              <button
                className={modelFill === MODELFILL.WORK ? "modal_active" : ""}
                onClick={() => setModelFill(MODELFILL.WORK)}
              >
                Work
              </button>
              <button
                className={modelFill === MODELFILL.RESUME ? "modal_active" : ""}
                onClick={() => setModelFill(MODELFILL.RESUME)}
              >
                Resume
              </button>
            </div>
            {modelFill === MODELFILL.PERSONNEL ? (
              <form className="modal_filling" onSubmit={submitPersonel}>
                <div className="modal_filling_input">
                  <input
                    type="text"
                    placeholder="First Name"
                    value={personelData.personelFName}
                    onChange={(value) =>
                      setPersonelData((prev) => ({
                        ...prev,
                        personelFName: value.target.value,
                      }))
                    }
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={personelData.personelLName}
                    onChange={(value) =>
                      setPersonelData((prev) => ({
                        ...prev,
                        personelLName: value.target.value,
                      }))
                    }
                  />
                </div>
                <input
                  type="email"
                  placeholder="Email"
                  onChange={(value) =>
                    setPersonelData((prev) => ({
                      ...prev,
                      personelEmail: value.target.value,
                    }))
                  }
                  value={personelData.personelEmail}
                />
                <input
                  type="text"
                  placeholder="LinkedIn (Optional)"
                  onChange={(value) =>
                    setPersonelData((prev) => ({
                      ...prev,
                      personellinkedIn: value.target.value,
                    }))
                  }
                  value={personelData.personellinkedIn}
                />
                <input
                  type="tel"
                  placeholder="Phone Number"
                  onChange={(value) =>
                    setPersonelData((prev) => ({
                      ...prev,
                      personelPhone: value.target.value,
                    }))
                  }
                  value={personelData.personelPhone}
                />
                <input
                  type="text"
                  placeholder="Address"
                  onChange={(value) =>
                    setPersonelData((prev) => ({
                      ...prev,
                      personelAddress: value.target.value,
                    }))
                  }
                  value={personelData.personelAddress}
                />
                <button className="modal_submit" type={"submit"}>
                  Next
                </button>
              </form>
            ) : modelFill === MODELFILL.WORK ? (
              <form className="modal_filling" onSubmit={submitWork}>
                <input
                  type="text"
                  placeholder="Total Experience"
                  value={workData.workTotalExperience}
                  onChange={(e) =>
                    setworkData((prev) => ({
                      ...prev,
                      workTotalExperience: e.target.value,
                    }))
                  }
                />
                <input
                  type="text"
                  placeholder="Current CTC"
                  value={workData.workCurrentCTC}
                  onChange={(e) =>
                    setworkData((prev) => ({
                      ...prev,
                      workCurrentCTC: e.target.value,
                    }))
                  }
                />
                <input
                  type="text"
                  placeholder="Expected CTC"
                  value={workData.workExpectedCTC}
                  onChange={(e) =>
                    setworkData((prev) => ({
                      ...prev,
                      workExpectedCTC: e.target.value,
                    }))
                  }
                />
                <input
                  type="number"
                  placeholder="Notice Period (in days)"
                  value={workData.workNoticePeriod}
                  onChange={(e) =>
                    setworkData((prev) => ({
                      ...prev,
                      workNoticePeriod: e.target.value,
                    }))
                  }
                />
                <div className="modal_filling_relocate">
                  <h3>Are you willing to relocate</h3>
                  <div>
                    <span>
                      Yes
                      <input
                        type="checkbox"
                        checked={locate}
                        onClick={changeLocationPreferance}
                      />
                    </span>
                    <span>
                      No
                      <input
                        type="checkbox"
                        checked={!locate}
                        onClick={changeLocationPreferance}
                      />
                    </span>
                  </div>
                </div>
                <button className="modal_submit" type="submit" disabled={false}>
                  Next
                </button>
              </form>
            ) : (
              <form className="modal_filling" onSubmit={submitResume}>
                <h3>Upload Resume</h3>
                <input
                  type="file"
                  name="upload"
                  accept="application/pdf,application/vnd.ms-excel"
                  onChange={(e) => setResume([e.target.files])}
                />
                <textarea
                  name="message"
                  id="modal_message"
                  cols={30}
                  rows={10}
                  placeholder="Your Message"
                ></textarea>
                <button className="modal_submit" disabled={false} type="submit">
                  Submit
                </button>
              </form>
            )}
          </div>
        </Modal>
        <div className="careerHeader">
          <div className="careerHeader_center">
            <h2>Work With Us</h2>
            <button
              onClick={() =>
                jobsRef.current?.scrollIntoView({ behavior: "smooth" })
              }
            >
              Join Now
            </button>
          </div>
          <img src="/images/carrer/carrer_bg.png" alt="Work with us" />
        </div>
        <div className="aboutWorking">
          <h2 id="heading">About Working Here</h2>
          <div>
            <AboutWorkingContatiner
              icon="/images/carrer/employee_recogition.svg"
              heading="Employ Recognition"
              text="In line with our pursuit of innovation & excellence, we create a culture of belonging where recognition lets everyone is seen, heard and appreciated for who they are and the work they do. for the outstanding employee performance we initiates programs like employee of the month in every 3 months."
            />
            <AboutWorkingContatiner
              icon="/images/carrer/learning_culture.svg"
              heading="Culture of Learning"
              text="To provide our employees with opportunities and experiences that help them to grow and learn, both as a professional and as a human being. For us, learning is an on-going and an everyday process to a better self."
            />
            <AboutWorkingContatiner
              icon="/images/carrer/flexible_hours.svg"
              heading="Flexible Hours"
              text="Flexible working hours are the new normal. We at Rareprob believe giving employees freedom to choose when to work, how to work. It helps them thrive and also balance their life better."
            />
            <AboutWorkingContatiner
              icon="/images/carrer/freindly_work.svg"
              heading="Friendly Work Environment"
              text="Our culture is special. We do everything in our power to create an engaged and fulfilling work  friendly environment for team members."
            />
          </div>
        </div>
        <div ref={jobsRef} className="h-14"></div>
        <div className="jobs">
          <h2 id="heading">Open Positions</h2>
          <div className="jobs_headingContainer">
            <div className="job_position ">
              <Accordion allowZeroExpanded={true}>

              <AccordionItem
                  style={{
                    border: "1.5px solid black",
                    borderRadius: "1.5px",
                    margin: "12px",
                  }}
                >
                  <AccordionItemHeading className="accordion__arrow">
                    <AccordionItemButton>
                      <h4 className="job_position_heading">
                      SR. GRAPHIC DESIGNER
                      </h4>

                      <div className="job_position_heading_container">
                        <p>
                          <strong>Location:</strong> Dehradun.
                        </p>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>

                  <AccordionItemPanel>
                    <div className="job_position_bottom_container">
                      <p className="job_position_bottom">
                      We are seeking a highly creative and experienced Senior Graphic Designer to join our team. The ideal candidate will have a strong portfolio, excellent design skills, and the ability to translate concepts into visually compelling assets. This role involves leading design projects, mentoring junior designers, and collaborating with cross-functional teams to deliver impactful visual content.
                      </p>
                      <div className="job_position_bottom_detail">
                        <h5>Key Responsibilities:</h5>
                        <li>
                        Lead the creation of visual assets for mobile applications, marketing materials, and corporate branding.
                        </li>
                        <li>
                        Ensure consistent brand identity across all platforms, including app interfaces, websites, promotional content, and social media.
                        </li>
                        <li>
                        Collaborate with UI/UX designers and developers to create intuitive and visually appealing app designs.
                        </li>
                        <li>
                        Develop wire-frames, mock ups, and prototypes for mobile interfaces with a focus on user experience and engagement.
                        </li>
                        <li>
                        Design creative assets for digital marketing campaigns, including google ads, banners, email templates, and social media posts.
                        </li>
                        <li>Creating play-store videos and google video adds.</li>
                        <li>Provide guidance to junior designers and ensure timely delivery of high-quality designs.</li>
                        <li>Share best practices and industry trends to inspire and develop the design team.</li>
                        <li>Manage multiple design projects simultaneously, ensuring deadlines and quality standards are met.</li>
                        <li> Collaborate with cross-functional teams, including marketing, development, and product management, to meet project goals.</li>

                        <strong> Requirements:</strong>

                        <li>
                        Bachelor’s degree in Graphic Design, Visual Communication, or related field.
                        </li>
                        <li>A minimum of 5-7 years of experience in graphic design, preferably within the IT or tech industry.</li>

                        <strong> Technical Skills:</strong>

<li>
Proficiency in design software, including Adobe Creative Suite (Photoshop, Illustrator, Adobe XD, After Effects).
</li>
<li>Knowledge of design tools for UI/UX such as Figma, Sketch, or Adobe XD.</li>
<li>Must have knowledge of Video editing, Social media post.</li>
           <li> Knowledge of Google ad campaign, Google play store assests.</li>          
           <li> Understanding of responsive design principles UI/UX.</li>  
           <li>Basic knowledge of front-end technologies (HTML, CSS) is a plus.</li>
           <strong>Creative Skills:</strong>
           <li>Strong portfolio showcasing diverse design projects, particularly in mobile app design and branding</li>
                        <button
                          onClick={() => openModel("SR. GRAPHIC DESIGNER")}
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem
                  style={{
                    border: "1.5px solid black",
                    borderRadius: "1.5px",
                    margin: "12px",
                  }}
                >
                  <AccordionItemHeading className="accordion__arrow">
                    <AccordionItemButton>
                      <h4 className="job_position_heading">
                        ANDROID DEVELOPER
                      </h4>

                      <div className="job_position_heading_container">
                        <p>
                          <strong>Experience required :</strong> 3 to 6 Years.
                        </p>
                        <p>
                          <strong>Location:</strong> Dehradun.
                        </p>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="job_position_bottom_container">
                      <p className="job_position_bottom">
                        We are seeking a talented and experienced Android
                        Developer with a strong background in image processing
                        and video editing. As a key member of our mobile
                        development team, you will be responsible for designing,
                        developing, and maintaining cutting-edge Android
                        applications focused on image manipulation and video
                        editing functionalities.
                      </p>
                      <div className="job_position_bottom_detail">
                        <h5>Responsibilities:</h5>
                        <li>
                          Collaborate with cross-functional teams to define,
                          design, and ship new features.
                        </li>
                        <li>
                          Architect, design, and implement advanced image
                          processing algorithms and video editing tools.
                        </li>
                        <li>
                          Develop efficient and scalable code optimized for
                          mobile platforms.
                        </li>
                        <li>
                          Ensure the performance, quality, and responsiveness of
                          applications.
                        </li>
                        <li>
                          Identify and resolve bottlenecks, bugs, and other
                          technical issues.
                        </li>
                        <li>
                          Continuously discover, evaluate, and implement new
                          technologies to maximize development efficiency.
                        </li>
                        <li>
                          Mentor junior developers and provide technical
                          guidance and support.
                        </li>
                        <li>
                          Stay updated with the latest trends, techniques, and
                          best practices in mobile development, image
                          processing, and video editing.
                        </li>
                        <br />
                        <h5>Requirements and skills :</h5>
                        <li>
                          Bachelor's or Master's degree in Computer Science,
                          Engineering, or a related field.
                        </li>
                        <li>
                          5-6 years of professional experience in Android
                          application development.
                        </li>
                        <li>
                          Strong proficiency in Java or Kotlin programming
                          languages.
                        </li>
                        <li>
                          Extensive experience with Android SDK, Android Studio,
                          and related development tools.
                        </li>
                        <li>
                          Deep understanding of image processing techniques,
                          algorithms, and libraries (e.g., OpenCV, TensorFlow).
                        </li>
                        <li>
                          Proven track record of developing and shipping
                          high-quality Android applications with complex
                          features.
                        </li>
                        <li>
                          Experience with video editing frameworks and libraries
                          (e.g., FFmpeg, ExoPlayer).
                        </li>
                        <li>
                          Solid understanding of software development
                          principles, design patterns, and best practices.
                          Excellent problem-solving skills and attention to
                          detail.
                        </li>
                        <li>Strong communication and collaboration skills.</li>
                        <li>
                          Ability to work effectively in a fast-paced
                          environment and meet tight deadlines.
                        </li>
                        <li>
                          Knowledge of computer vision, machine learning, or
                          artificial intelligence concepts.
                        </li>
                        <li>
                          Familiarity with agile development methodologies
                          (e.g., Scrum, Kanban).
                        </li>
                        <li>
                          Contributions to open-source projects or a strong
                          online presence in the developer community.
                        </li>
                        <li>
                          Join us in revolutionizing the way people interact
                          with images and videos on their mobile devices! If you
                          are passionate about mobile development and have a
                          knack for pushing the boundaries of what's possible,
                          we'd love to hear from you.
                        </li>
                        <button onClick={() => openModel("ANDROID DEVELOPER")}>
                          Apply Now
                        </button>
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                 
                <AccordionItem
                  style={{
                    border: "1.5px solid black",
                    borderRadius: "1.5px",
                    margin: "12px",
                  }}
                >
                  <AccordionItemHeading className="accordion__arrow">
                    <AccordionItemButton>
                      <h4 className="job_position_heading">
                        Associate Product Analyst
                      </h4>

                      <div className="job_position_heading_container">
                        {/* <p>
                          <strong>Experience required :</strong> 1 to 3 Years
                        </p> */}
                        <p>
                          <strong>Location:</strong> Dehradun.
                        </p>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>

                  <AccordionItemPanel>
                    <div className="job_position_bottom_container">
                      <p className="job_position_bottom">
                        We are seeking a dynamic and analytical MBA fresher to
                        join our team as a Associate Product Analyst. As a
                        Associate Product Analyst, you will play a crucial role
                        in analyzing market trends, assessing product
                        performance, and identifying opportunities for product
                        enhancement. This role offers an exciting opportunity to
                        apply your business acumen and analytical skills to
                        drive product strategy and success.
                        <p>
                          Frehser with MBA (IT/Business Analytics/Marketing) are
                          highly preferred.
                        </p>
                      </p>

                      <div className="job_position_bottom_detail">
                        <h5>Roles & Responsibilities :</h5>
                        <strong>Market Analysis:</strong>
                        <li>
                          Conduct market research to identify industry trends,
                          competitive landscape, and customer preferences.
                          Analyze market data to assess potential market
                          opportunities and threats. Provide insights and
                          recommendations to support product positioning and
                          differentiation.
                        </li>
                        <strong>Product Performance Evaluation:</strong>
                        <li>
                          Analyze product performance data to identify areas for
                          improvement and optimization.
                        </li>
                        <li>
                          Collaborate with cross-functional teams to implement
                          strategies to enhance product performance.
                        </li>
                        <strong>Competitive Analysis:</strong>
                        <li>
                          Track competitor activities and product offerings.
                          Benchmark product features and pricing against
                          competitors. Identify competitive strengths and
                          weaknesses to inform product strategy.
                        </li>
                        <strong>Product Strategy Support:</strong>
                        <li>
                          Assist in developing product roadmaps based on market
                          insights and business objectives.
                        </li>
                        <li>
                          Provide analytical support for product pricing,
                          positioning, and go-to-market strategies.
                        </li>
                        <li>
                          Collaborate with product managers to prioritize
                          features and enhancements based on market demand and
                          business impact.
                        </li>
                        <strong>Reporting and Presentation:</strong>
                        <li>
                          Prepare reports and presentations summarizing key
                          findings and insights.
                        </li>
                        <li>
                          Present analysis and recommendations to internal
                          stakeholders, including senior management.
                        </li>
                        <h5>Qualifications:</h5>
                        <li>Graduation.</li>
                        <li>
                          MBA degree from a reputable institution in (Marketing,
                          IT, Digital Marketing) or related field required.
                        </li>
                        <li>
                          Strong analytical skills with proficiency in Excel,
                          data analysis tools, and statistical techniques.
                        </li>
                        <li>
                          Excellent communication and presentation skills.
                          Strong attention to detail and problem-solving
                          abilities.
                        </li>
                        <li>Basic knowledge of Google Analytics and SQL</li>
                        <li>
                          Strong attention to detail and problem-solving
                          abilities.
                        </li>
                        <li>Passion for technology and innovation.</li>
                        <button
                          onClick={() => openModel("ASSOCIATE PRODUCT ANALYST")}
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem
                  style={{
                    border: "1.5px solid black",
                    borderRadius: "1.5px",
                    margin: "12px",
                  }}
                >
                  <AccordionItemHeading className="accordion__arrow">
                    <AccordionItemButton>
                      <h4 className="job_position_heading">
                        PPC MARKETING EXPERT
                      </h4>

                      <div className="job_position_heading_container">
                        <p>
                          <strong>Location:</strong> Dehradun.
                        </p>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>

                  <AccordionItemPanel>
                    <div className="job_position_bottom_container">
                      <p className="job_position_bottom">
                        We are seeking a skilled and experienced PPC Marketing
                        Expert to join our dynamic marketing team. As a Games
                        PPC Marketing Expert, you will be responsible for
                        developing, implementing, and optimizing pay-per-click
                        (PPC) advertising campaigns to drive user acquisition,
                        engagement, and revenue for our gaming products.
                      </p>
                      <div className="job_position_bottom_detail">
                        <h5>Roles & Responsibilities :</h5>
                        <p>
                          <strong>1. </strong>
                          Develop and execute PPC advertising strategies
                          tailored to specific gaming products, platforms, and
                          target audiences.
                        </p>
                        <p>
                          <strong>2. </strong>
                          Conduct keyword research and analysis to identify
                          high-performing keywords and optimize campaigns for
                          maximum ROI.
                        </p>
                        <p>
                          <strong>3. </strong>
                          Create compelling ad copy and creatives that resonate
                          with gaming audiences and drive clicks and
                          conversions.
                        </p>
                        <p>
                          <strong>4. </strong>
                          Manage and optimize PPC campaigns across multiple
                          platforms, including Google Ads, Facebook Ads, and
                          other relevant networks.
                        </p>
                        <p>
                          <strong>5. </strong>
                          Monitor campaign performance, analyze key metrics, and
                          provide actionable insights and recommendations for
                          improvement.
                        </p>
                        <p>
                          <strong>6. </strong>
                          Collaborate with cross-functional teams, including
                          game developers, designers, and analysts, to align PPC
                          strategies with overall marketing objectives.
                        </p>
                        <p>
                          <strong>7. </strong>
                          Stay updated on industry trends, best practices, and
                          algorithm changes to ensure our PPC campaigns remain
                          competitive and effective.
                        </p>
                        <p>
                          <strong>8. </strong>
                          Experiment with new ad formats, targeting options, and
                          optimization techniques to continuously improve
                          campaign performance.
                        </p>
                        <p>
                          <strong>9. </strong>
                          Track and report on key performance indicators (KPIs)
                          such as click-through rate (CTR), conversion rate,
                          cost per acquisition (CPA), and return on ad spend
                          (ROAS).
                        </p>
                        <p>
                          <strong>10. </strong>
                          Stay abreast of competitor activity and market trends
                          to identify opportunities and potential threats.
                        </p>
                        <h5>Qualifications :</h5>
                        <p>
                          <strong>1. </strong>
                          Bachelor's degree in Marketing, Advertising, Business,
                          or a related field.
                        </p>
                        <p>
                          <strong>2. </strong>
                          Proven experience managing PPC advertising campaigns
                          for gaming products, with a strong track record of
                          driving results.
                        </p>
                        <p>
                          <strong>3. </strong>
                          Deep understanding of PPC platforms such as Google
                          Ads, Facebook Ads, and others, along with proficiency
                          in campaign setup, optimization, and performance
                          tracking.
                        </p>
                        <p>
                          <strong>4. </strong>
                          Excellent analytical skills and the ability to
                          interpret complex data to make informed decisions and
                          optimizations.
                        </p>
                        <p>
                          <strong>5. </strong>
                          Strong creative skills with the ability to craft
                          engaging ad copy and visuals that resonate with gaming
                          audiences.
                        </p>
                        <p>
                          <strong>6. </strong>
                          Familiarity with A/B testing methodologies and a
                          data-driven approach to campaign optimization.
                        </p>
                        <p>
                          <strong>7. </strong>
                          Excellent communication and collaboration skills, with
                          the ability to work effectively in a cross-functional
                          team environment.
                        </p>
                        <p>
                          <strong>8. </strong>
                          Self-motivated and proactive, with a passion for
                          gaming and a desire to stay at the forefront of
                          digital marketing trends and technologies.
                        </p>
                        <button
                          onClick={() => openModel("PPC MARKETING EXPERT")}
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem
                  style={{
                    border: "1.5px solid black",
                    borderRadius: "1.5px",
                    margin: "12px",
                  }}
                >
                  <AccordionItemHeading className="accordion__arrow">
                    <AccordionItemButton>
                      <h4 className="job_position_heading">
                      2D GAME DESIGNER
                      </h4>

                      <div className="job_position_heading_container">
                        <p>
                          <strong>Location:</strong> Dehradun.
                        </p>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>

                  <AccordionItemPanel>
                    <div className="job_position_bottom_container">
                    <p className="job_position_bottom">  Greetings from Rareprob ! </p>
                      <p className="job_position_bottom">
                      We are seeking a talented and passionate 2D Game Designer to join our dynamic game development team. The ideal candidate will be responsible for creating intuitive and visually appealing user interfaces that enhance the player experience. You will collaborate closely with game designers, artists, and programmers to ensure the game's UI/UX design aligns with the overall vision and gameplay mechanics.
                      </p>
                      <div className="job_position_bottom_detail">
                        <h5>Key Responsibilities:</h5>
                        <p>
                          <strong>1. </strong>
                          Design and create user interfaces for in-game menus, HUDs, icons, buttons, and other interactive elements.
                        </p>
                        <p>
                          <strong>2. </strong>
                          Develop and optimize user flows, wireframes, prototypes, and interaction designs to ensure an intuitive and engaging player experience.
                        </p>
                        <p>
                          <strong>3. </strong>
                          Work closely with game designers, artists, and programmers to integrate UI/UX elements seamlessly into the game.
                        </p>
                        <p>
                          <strong>4. </strong>
                          Conduct user research, gather feedback, and analyze user behavior to inform design decisions and improve the overall user experience.
                        </p>
                        <p>
                          <strong>5. </strong>
                          Create interactive prototypes to test and refine UI/UX designs, ensuring they meet usability standards and player expectations.
                        </p>
                        <p>
                          <strong>6. </strong>
                          Maintain visual consistency across all UI elements, adhering to the game's art style and branding guidelines.
                        </p>
                        <p>
                          <strong>7. </strong>
                          Iterate on designs based on feedback from playtests, team reviews, and user data to continually improve the UI/UX.
                        </p>
                        <p>
                          <strong>8. </strong>
                          Produce detailed design documentation, including UI specifications, interaction guidelines, and user flows.
                        </p>
                        <h5>Qualifications :</h5>
                        <p>
                          <strong>1. </strong>
                          2 to 4 years of experience in UI/UX design, preferably within the gaming industry.
                        </p>
                        <p>
                          <strong>2. </strong>
                          Proficiency in design tools such as Adobe XD, Figma, Sketch, Photoshop, Illustrator.
                        </p>
                        <p>
                          <strong>3. </strong>
                          Strong understanding of game design principles, user-centered design, interaction design, and visual design.
                        </p>
                        <p>
                          <strong>4. </strong>
                          Ability to create visually appealing and functional designs that enhance the player experience.
                        </p>
                        <p>
                          <strong>5. </strong>
                          Strong analytical and problem-solving skills, with the ability to find creative solutions to design challenges.
                        </p>
                        <p>
                          <strong>6. </strong>
                          Excellent communication and collaboration skills, with the ability to present and defend design decisions.
                        </p>
                        <p>
                          <strong>7. </strong>
                          Ability to work in a fast-paced, iterative development environment and adapt to changing project requirements.
                        </p>
                        <h5> Preferred Qualifications:</h5>
                        <p>
                          <strong>Education :</strong>
                          Bachelor's degree in Graphic Design, Interaction Design, Game Design, or a related field..
                        </p>
                        <p>
                          <strong>Portfolio :</strong>
                          A strong portfolio showcasing your UI/UX design work, especially within gaming projects.
                        </p>
                        <p>
                          <strong>Technical Skills :</strong>
                          Basic knowledge Unity or other game engines and Animator Software like Spine2D is a plus.
                        </p>
                        <p>
                          <strong>Passion :</strong>
                          A genuine passion for video games and a deep understanding of what makes a great gaming experience.
                        </p>
                        <button
                          onClick={() => openModel("2D GAME DESIGNER")}
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                 <AccordionItem
                  style={{
                    border: "1.5px solid black",
                    borderRadius: "1.5px",
                    margin: "12px",
                  }}
                >
                  <AccordionItemHeading className="accordion__arrow">
                    <AccordionItemButton>
                      <h4 className="job_position_heading">
                      DIGITAL MARKETING SPECIALIST
                      </h4>

                      <div className="job_position_heading_container">
                        <p>
                          <strong>Location:</strong> Dehradun.
                        </p>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>

                  <AccordionItemPanel>
                    <div className="job_position_bottom_container">
                      <p className="job_position_bottom">
                      We are seeking a highly skilled and experienced Digital Marketing expert to join our team. The ideal candidate will possess a strong background in digital marketing, with expertise in areas such as Strategic Branding, Content Marketing, SEO, SEM, Social Media Marketing, Pay-Per-Click Advertising, Online Reputation Management etc with deep understanding of digital marketing tools and trends. The candidate should be a strategic thinker, result oriented, analytical, and capable of implementing effective online brand strategies.
                      </p>
                      <div className="job_position_bottom_detail">
                        <h5>Roles and Responsibilities:</h5>
                        <p>
                        Develop and execute comprehensive digital marketing strategies across multiple channels, including social media, email, search engine optimization (SEO), pay-per-click advertising (PPC), and content marketing.
                        </p>
                        <p>
                        Manage and optimize Facebook ads campaigns to achieve desired performance metrics such as click-through rates, conversion rates, and return on ad spend (ROAS).
                        </p>
                        <p>
                        Collaborate with cross-functional teams to launch new products or services by developing targeted BTL campaigns that drive brand awareness and lead generation.
                        </p>
                        <p>
                        Analyze campaign performance using data analytics tools like Google Analytics to identify areas of improvement and adjust strategies accordingly.
                        </p>
                        <p>
                        Stay up-to-date with industry trends and best practices in digital marketing to continuously improve skills and knowledge.
                        </p>

                        <strong> Required Skill Set & Experience</strong>

                        <p>
                        Proven experience in digital marketing within the IT industry.
                        </p>
                        <p>Expertise in Ecommerce, Web, and Mobile Apps marketing.</p>
                        <p>
                        In-depth knowledge of SEO, SEM, Google Analytics, and other digital marketing tools.
                        </p>
                        <p>
                        Experience in managing and optimizing paid advertising campaigns.
                        </p>
                        <p>
                        Exceptional communication and interpersonal skills.
                        </p>
                        <p>
                        Strong understanding of digital campaign management principles, including planning, execution, tracking & analysis.
                        </p>
                        <p>
                        Proficiency in managing multiple projects simultaneously while prioritizing tasks effectively under tight deadlines.
                        </p>
                        <h5>Qualifications :</h5>
                        <p>MBA degree in marketing, Business, or related field.</p>
                        <p>6 to 8 years of experience in digital marketing roles.</p>
                        <p>Demonstrated success in developing and implementing digital marketing strategies for IT organizations.</p>
                        <p>Proven experience in team leadership and collaboration.</p>
                        <button
                          onClick={() => openModel("DIGITAL MARKETING SPECIALIST")}
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
        <div style={{ height: "200px" }}></div>
      </main>
      <NewFooter color="bg-black/80" />
    </>
  );
};

export default CareerPage;

interface ABOUTWORKINGTYPE {
  heading: string;
  text: string;
  icon: string;
}

const AboutWorkingContatiner: React.FC<ABOUTWORKINGTYPE> = ({
  heading,
  text,
  icon,
}) => {
  return (
    <div className="aboutWorking_Container">
      <img src={icon} alt="Trpohy" />
      <div>
        <h3>{heading}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};

interface JOBCONTAINER {
  openModel(position: string): void;
  position: string;
  positionText: string;
}

const JobContainer: React.FC<JOBCONTAINER> = ({
  openModel,
  position,
  positionText,
}) => {
  return (
    <div className="job_positionContainer">
      <div>
        <h4>{position} </h4>
        <p>{positionText}</p>
      </div>
      <button onClick={() => openModel(position)}>Apply Now</button>
    </div>
  );
};
