import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { FaCalendar,FaFileDownload,FaFilter,FaSearch } from "react-icons/fa";
import "./CareerLogin.css";

const CareerLogin = () => {
  const [todos, setTodos] = useState<any[]>([]);
  const [filters, setFilters] = useState({
    searchText: "",
    position: "",
    dateFrom: "",
    dateTo: "",
    experience: "",
    relocate: "all",
  });
  const [sortBy, setSortBy] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");

  const careerdata = async () => {
    const aman: any[] = [];
    await getDocs(collection(firestore, "ResumeCarrer")).then(
      (querySnapshot) => {
        querySnapshot.docs.forEach((value) => {
          aman.push(value.data());
        });
        setTodos(aman);
      }
    );
  };

  useEffect(() => {
    careerdata();
  }, []);

  const filterResumes = (resumes: any[]) => {
    return resumes.filter((resume) => {
      // Text search
      const matchesText =
        filters.searchText.toLowerCase() === "" ||
        resume.position.toLowerCase().includes(filters.searchText.toLowerCase()) ||
        resume.personelData.personelFName
          .toLowerCase()
          .includes(filters.searchText.toLowerCase()) ||
        resume.personelData.personelLName
          .toLowerCase()
          .includes(filters.searchText.toLowerCase());

      // Position filter
      const matchesPosition =
        filters.position === "" ||
        resume.position.toLowerCase().includes(filters.position.toLowerCase());

      // Date range filter - Convert string dates to Date objects for comparison
      const resumeDate = new Date(resume.date);
      resumeDate.setHours(0, 0, 0, 0); // Reset time part to compare dates only
      
      let matchesDate = true;
      if (filters.dateFrom) {
        const fromDate = new Date(filters.dateFrom);
        fromDate.setHours(0, 0, 0, 0);
        matchesDate = matchesDate && resumeDate >= fromDate;
      }
      if (filters.dateTo) {
        const toDate = new Date(filters.dateTo);
        toDate.setHours(0, 0, 0, 0);
        matchesDate = matchesDate && resumeDate <= toDate;
      }

      // Relocation filter
      const matchesRelocate =
        filters.relocate === "all" ||
        (filters.relocate === "yes" && resume.workData.workRelocate) ||
        (filters.relocate === "no" && !resume.workData.workRelocate);

      return matchesText && matchesPosition && matchesDate && matchesRelocate;
    });
  };

  const sortResumes = (resumes: any[]) => {
    return [...resumes].sort((a, b) => {
      if (sortBy === "date") {
        return sortOrder === "desc"
          ? new Date(b.date).getTime() - new Date(a.date).getTime()
          : new Date(a.date).getTime() - new Date(b.date).getTime();
      }
      if (sortBy === "name") {
        const nameA = `${a.personelData.personelFName} ${a.personelData.personelLName}`;
        const nameB = `${b.personelData.personelFName} ${b.personelData.personelLName}`;
        return sortOrder === "desc"
          ? nameB.localeCompare(nameA)
          : nameA.localeCompare(nameB);
      }
      return 0;
    });
  };

  const handleFilterChange = (name: string, value: string) => {
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const filteredAndSortedResumes = sortResumes(filterResumes(todos));

  return (
    <div className="p-6">
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-6">HR Resume Management Panel</h2>
        
        {/* Search and Filter Panel */}
        <div className="bg-white p-4 rounded-lg shadow-md mb-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            {/* Text Search */}
            <div className="flex items-center border rounded-md p-2">
              <FaSearch className="w-5 h-5 text-gray-400 mr-2" />
              <input
                type="text"
                placeholder="Search by name or position..."
                className="w-full outline-none"
                value={filters.searchText}
                onChange={(e) => handleFilterChange("searchText", e.target.value)}
              />
            </div>

            {/* Position Filter */}
            <div className="flex items-center border rounded-md p-2">
              <FaFilter className="w-5 h-5 text-gray-400 mr-2" />
              <input
                type="text"
                placeholder="Filter by position..."
                className="w-full outline-none"
                value={filters.position}
                onChange={(e) => handleFilterChange("position", e.target.value)}
              />
            </div>


          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* Date Range */}
            <div className="flex items-center gap-2">
              <FaCalendar className="w-5 h-5 text-gray-400" />
              <input
                type="date"
                className="border rounded-md p-2"
                value={filters.dateFrom}
                onChange={(e) => handleFilterChange("dateFrom", e.target.value)}
              />
              <span>to</span>
              <input
                type="date"
                className="border rounded-md p-2"
                value={filters.dateTo}
                onChange={(e) => handleFilterChange("dateTo", e.target.value)}
              />
            </div>

            {/* Relocation Filter */}
            <div className="flex items-center border rounded-md p-2">
              <select
                className="w-full outline-none"
                value={filters.relocate}
                onChange={(e) => handleFilterChange("relocate", e.target.value)}
              >
                <option value="all">All Relocation Preferences</option>
                <option value="yes">Ready to Relocate</option>
                <option value="no">Not Ready to Relocate</option>
              </select>
            </div>

            {/* Sort Options */}
            <div className="flex items-center gap-2">
              <select
                className="border rounded-md p-2"
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
              >
                <option value="date">Sort by Date</option>
                <option value="name">Sort by Name</option>
              </select>
              <button
                className="p-2 border rounded-md"
                onClick={() => setSortOrder(prev => prev === "asc" ? "desc" : "asc")}
              >
                {sortOrder === "asc" ? "↑" : "↓"}
              </button>
            </div>
          </div>
        </div>

        {/* Results Count */}
        <div className="mb-4 text-gray-600">
          Found {filteredAndSortedResumes.length} candidates
        </div>

        {/* Results Grid */}
        <div className="grid gap-4">
          {filteredAndSortedResumes.map((value, index) => (
            <div 
              key={index}
              className="bg-white p-4 rounded-lg shadow-md flex justify-between items-start hover:shadow-lg transition-shadow"
            >
              <div>
                <h4 className="text-xl font-semibold mb-2">
                  {`${value?.personelData.personelFName} ${value?.personelData.personelLName}`}
                </h4>
                <p className="text-lg text-blue-600 mb-2">{value.position}</p>
                <p className="text-gray-600 mb-1">
                  Experience: {value?.workData.workTotalExperience}
                </p>
                <p className="text-gray-600 mb-1">
                  {value.workData.workRelocate ? "Ready To Relocate" : "Not Ready to Relocate"}
                </p>
                <p className="text-gray-600 mb-1">{value.personelData.personelAddress}</p>
                <p className="text-gray-600">{value.personelData.personelPhone}</p>
              </div>
              <div className="flex flex-col items-end">
                <a 
                  href={value?.cv} 
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mb-2"
                >
                  <FaFileDownload className="w-6 h-6 text-blue-600 hover:text-blue-800" />
                </a>
                <p className="text-sm text-gray-500">{value.date}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CareerLogin;
   