import { useState, useRef } from "react";
import { setDoc, doc } from "@firebase/firestore";
import { firestore } from "../firebase";
import { getAnalytics, logEvent } from "firebase/analytics";

// import emailjs from '@emailjs/browser';

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [detail, setDetail] = useState("");
  const [succesfull, setSuccesfull] = useState(false);
  const [validate, setValidate] = useState(false);
  const analytics = getAnalytics();
  const form = useRef(null);

  const submitHandler = (e) => {
    logEvent(analytics, "select_content", {
      items: { name: "HomeScreen_Form_Section", event: "Form_Submit_Clicked" },
    });
    e.preventDefault();
    if (name.length > 0 && email.length > 0 && detail.length > 0) {
      console.log("Gone to Form");
      // emailjs.sendForm('service_fy5xe44', 'template_ajifg4q', `${form.current}`, '0BGMfbaqPEuqyLtle')
      // .then((result) => {
      //   console.log("Succesfull")
      //     console.log(result.text);
      // }, (error) => {
      //   console.log("Error")
      //     console.log(error.text);
      // });
      setDoc(doc(firestore, "user", email), {
        name: name,
        email: email,
        detail: detail,
      })
        .then(() => {
          setValidate(false);
          setName("");
          setEmail("");
          setDetail("");
          alert("Feedback Submitted Succesfully");
          setSuccesfull(true);
        })
        .catch((error) => console.log(error));
    } else {
      setValidate(true);
    }
  };

  return (
    <div
      className="h-[550px] bg-none md:bg-contact-pattern bg-cover pt-6  md:bg-gray-300 bg-opacity-10 w-full md:w-3/4 mx-auto rounded-3xl text-white   pl-10"
      id="start"
    >
      <div className="w-[300px] md:w-[400px]  h-[500px]  mb-20 rounded-3xl p-10 bg-purple-700 bg-opacity-90">
        <h1 className="text-4xl text-white ">Contact us</h1>
        {/* <p className="mt-4 font-medium">+91 82793 23413</p> */}
        <p className="mt-2 font-medium">✉️ info@rareprob.com</p>
        <p className="mt-2 text-sm">
          ⛳️ Rareprob Solutions Private Limited <br /> Doon Express Business
          Park, Subhash Nagar, Dehradun, Uttarakhand - 248001
        </p>
        <form
          ref={form}
          action="submit"
          className="flex flex-col items-center justify-center"
          onSubmit={submitHandler}
        >
          <input
            className="appearance-none bg-transparent border-b-[0.2px] w-full text-gray-200 mr-3 py-1  leading-tight focus:outline-none mt-4 "
            type="text"
            name="user_name"
            placeholder="Name"
            aria-label="Full name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            className="appearance-none bg-transparent border-b-[0.2px] w-full text-gray-200 mr-3 py-1  leading-tight focus:outline-none mt-4 "
            type="text"
            name="user_email"
            placeholder="Email"
            aria-label="Full name"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="appearance-none bg-transparent border-b-[0.2px] w-full text-gray-200 mr-3 py-1  leading-tight focus:outline-none mt-4 "
            type="text"
            name="user_message"
            placeholder="Message"
            aria-label="Full name"
            value={detail}
            onChange={(e) => setDetail(e.target.value)}
          />
          {validate && <p style={{ color: "red" }}>All fields are required</p>}
          <button
            disabled={succesfull}
            sucess={succesfull}
            type="submit"
            className={`w-40 mt-10 lg:mt-20 h-10 rounded-xl ${
              succesfull ? "bg-gray-500" : "bg-[#A45DFF]"
            } p-2 `}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
